<template>
  <div>
    <v-container v-if="componentLoading">
      <v-row justify="center">
        <v-col xs="12" lg="6">
          <h3>
            Deal List {{ listType }}
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </h3>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-if="!componentLoading">
      <v-row justify="center">
        <v-col xs="12" xl="8">
          <h3>Deal List {{ listType }}</h3>
          <br />
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          <br />
          <v-data-table
            @click:row="handleClick"
            :headers="headers"
            :items="dealList"
            :search="search"
            :items-per-page="-1"
          >
          <template v-slot:item.province="{ item }"> {{ item.province | capitalize }}</template>
              <template v-slot:item.amountRequested="{ item }"> {{ item.amountRequested | currency }}</template>
              <template v-slot:item.ltv="{ item }"> {{ item.ltv }}%</template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import API from "@/plugins/API";

export default {
  name: "DealRead",
  props: ["dealId"],
  data() {
    return {
      listType: null,
      search: "",
      headers: [
        {
          text: "First Name",
          align: "start",
          filterable: true,
          value: "clientFirstName",
        },
        {
          text: "Last Name",
          align: "start",
          filterable: true,
          value: "clientLastName",
        },
        {
          text: "City",
          value: "city",
        },
        {
          text: "Province",
          value: "province",
        },
        {
          text: "LTV",
          value: "ltv",
        },
        {
          text: "Requested",
          value: "amountRequested",
        },
        {
          text: "Status",
          value: "status",
        },
      ],
      componentLoading: true,
      error: "",
      dealList: [],
    };
  },
  filters: {
    capitalize(value){
      return value.toUpperCase()
    },
    currency(value){
     return new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD', maximumFractionDigits: 0}).format(parseInt(value));
    
    }
  },
  methods: {
    handleClick(value) {
      this.$router.push(`/dashboard/deal/read/${value.id}`);
    },
  },
  watch: {
    "$route.params.status"(val) {
      // call the method which loads your initial state
      this.find();
    },
  },
  mounted() {
    console.log("mounted");
    // console.log('route query', this.$route.query)

    if(this.$route.query.status == 'all') {
      this.listType = '- All'
    } else if (this.$route.query.status == 'active') {
      this.listType = '- Active'
    } else if (this.$route.query.status == 'awarded') {
      this.listType = '- Awarded'
    } else if (this.$route.query.status == 'funded') {
      this.listType = '- Funded'
    } else if (this.$route.query.status == 'inactive') {
      this.listType = '- Inactive'
    }

    API()
      .get(`/deal/list?status=${this.$route.query.status}`)
      .then((res) => {
        console.log(res);
        this.dealList = res.data.recordset;
        this.componentLoading = false;
      })
      .catch((e) => {
        console.log("error");
        console.log(e);
      });
  },
};
</script>

<style>
</style>